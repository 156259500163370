import { Container, Header } from '@/src/modules/login/StyledComponents'
import PurchaseFlowMaxAlert from '@/src/common/components/elements/Alerts/PurchaseFlowMaxAlert'
import AlertWithIcon from '@/src/common/components/elements/Alerts/AlertWithIcon'
import { useLoginContext } from '@/src/modules/login/LoginContext'
import { ReactNode } from 'react'
import { useCartContext } from '@/src/common/contexts/CartContext'

type TLoginLayoutProps = {
    children: ReactNode
}

const LoginFormBaseLayout = ({ children }: TLoginLayoutProps) => {
    const {
        actionText,
        requiresKYC,
        isOverLimits,
        isAdaChat,
        showResetPassword,
    } = useLoginContext()
    const { hasSMBCard } = useCartContext()
    const showKYCAlert: boolean = requiresKYC || isOverLimits

    if (showResetPassword) {
        return (
            <Container>
                <Header>Reset Password</Header>
                {children}
            </Container>
        )
    }

    return (
        <Container>
            {showKYCAlert && (
                <PurchaseFlowMaxAlert
                    isLogin={true}
                    style={{ marginBottom: 30 }}
                    hitsWeeklyLimit={isOverLimits}
                    hasSMBCard={hasSMBCard}
                />
            )}
            {isAdaChat && (
                <AlertWithIcon iconName={'warning'} size={'sm'}>
                    <p>
                        Your chat session will resume automatically after login.
                    </p>
                </AlertWithIcon>
            )}
            <Header>{actionText}</Header>
            {children}
        </Container>
    )
}

export default LoginFormBaseLayout
