import {
    FullWidthWrapper,
    HalfContentContainer,
    LeftContentWrapper,
    RightContentWrapper,
} from '@/src/common/components/elements/StyledComponents'
import LoginForm from '@/src/modules/login/LoginForm'
import AlertWithIcon from '@/src/common/components/elements/Alerts/AlertWithIcon'
import Link from 'next/link'
import Button from '@/src/common/components/marketing/buttons/Button'
import { signOut as authSignOut } from '@firebase/auth'
import { firebaseAuth } from '@/config/firebaseClientConfig'
import { isNativeApp } from '@/src/common/deviceInfo'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { useEffect } from 'react'

type TLoginCustom = {
    children: JSX.Element | JSX.Element[]
    showCorporateLogin?: boolean
    isFullWidth?: boolean
    setError?: Function
    error?: string
}
function openChatBot() {
    try {
        window.adaEmbed.toggle()
    } catch {
        console.log('Unable to open ADA chat window')
    }
}
// include left content as children
const LoginFormWithCustomLeftContent = ({
    children,
    showCorporateLogin = false,
    isFullWidth = false,
    setError,
    error = '',
}: TLoginCustom) => {
    const componentWidth = isFullWidth ? undefined : '1440'

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }, [])

    // if the user should be blocked from continuing after login
    // show them an error
    if (error) {
        return (
            <FullWidthWrapper width={componentWidth}>
                <HalfContentContainer>
                    <LeftContentWrapper>{children}</LeftContentWrapper>
                </HalfContentContainer>
                <HalfContentContainer background={'lightest'}>
                    <RightContentWrapper style={{ margin: 'auto', width: 600 }}>
                        <h3>Unable to access gift</h3>
                        <p style={{ lineHeight: 1.75, marginBottom: 20 }}>
                            Your account does not have access to this gift. For
                            more information, please visit our{' '}
                            <Link href={'/faq'}>FAQ page</Link> or{' '}
                            <button
                                className={'action-text'}
                                onClick={() => openChatBot()}
                            >
                                chat with our GiftGuide
                            </button>
                            .
                        </p>
                        <AlertWithIcon iconName={'error'}>
                            <p>{error}</p>
                        </AlertWithIcon>
                        <Button
                            variant={'primary'}
                            size={'md'}
                            label={'sign out'}
                            textTransform={'uppercase'}
                            width={'standard'}
                            showArrow={true}
                            style={{ marginTop: 20 }}
                            onClick={() => {
                                authSignOut(firebaseAuth).then(() => {
                                    if (setError) {
                                        setError('')
                                    }
                                })
                                if (isNativeApp) {
                                    FirebaseAuthentication.signOut().then(
                                        () => {
                                            if (setError) {
                                                setError('')
                                            }
                                        }
                                    )
                                }
                            }}
                        />
                    </RightContentWrapper>
                </HalfContentContainer>
            </FullWidthWrapper>
        )
    }

    return (
        <FullWidthWrapper width={componentWidth}>
            <HalfContentContainer>
                <LeftContentWrapper>{children}</LeftContentWrapper>
            </HalfContentContainer>
            <HalfContentContainer background={'lightest'}>
                <RightContentWrapper>
                    <LoginForm showCorporateLogin={showCorporateLogin} />
                </RightContentWrapper>
            </HalfContentContainer>
        </FullWidthWrapper>
    )
}

export default LoginFormWithCustomLeftContent
