import { THandleListUpdate } from '@/src/modules/build-a-card/inputs/types'
import { BaseSyntheticEvent } from 'react'
import { EMAIL_DELIVERY, TEXT_DELIVERY } from '@/src/modules/pg-plus/constants'

export function handleRecipientChange({
    e,
    key,
    listOfRecipients,
    setListOfRecipients,
    recipientIndex,
    updateDelivery,
}: THandleListUpdate & {
    e: BaseSyntheticEvent
    updateDelivery?: boolean
}) {
    let trimmedValue = e.currentTarget.value.trimStart()
    const list = [...listOfRecipients]

    // update delivery is only used in bulk csv flows
    if (updateDelivery) {
        const deliveryType = list[recipientIndex]['recipientDelivery']['type']
        const storedAsEmail: boolean = deliveryType === EMAIL_DELIVERY
        const storedAsPhone: boolean = deliveryType === TEXT_DELIVERY

        // if updating email and phone set, clear phone
        if (
            key === 'recipientEmail' &&
            (list[recipientIndex]['recipientPhone'] ||
                !deliveryType ||
                storedAsPhone)
        ) {
            list[recipientIndex]['recipientDelivery']['type'] = EMAIL_DELIVERY
            list[recipientIndex]['recipientPhone'] = ''
        }

        // if updating phone and email set, clear email
        if (
            key === 'recipientPhone' &&
            (list[recipientIndex]['recipientEmail'] ||
                !deliveryType ||
                storedAsEmail)
        ) {
            list[recipientIndex]['recipientDelivery']['type'] = TEXT_DELIVERY
            list[recipientIndex]['recipientEmail'] = ''
        }
    }

    list[recipientIndex][key] = trimmedValue
    setListOfRecipients(list)
}
