export function scrollToTop(elementId: string) {
    const element = window.document.getElementById(elementId)
    const appHeader = window.document.getElementById('app-header-id')

    if (element) {
        const offset = appHeader ? appHeader.offsetHeight : 0 // Calculate the offset based on the header height

        window.scrollTo({
            top: element.getBoundingClientRect().top + window.scrollY - offset,
            behavior: 'smooth',
        })
    }
}

/**
 * Scroll to the top of the page without the header
 * @param elementId
 */
export function scrollToTopWithoutHeader(elementId: string) {
    const element = window.document.getElementById(elementId)

    if (element) {
        window.scrollTo({
            top: element.getBoundingClientRect().top + window.scrollY - 1000,
        })
    }
}
